#beneficios-page{padding: 44px 0px; position: relative;}
.beneficios-banner{color:#485860; font-family: 'Montserrat', sans-serif; display: grid; grid-template-columns: 0.766fr 1fr;
align-items: center;}
.beneficios-banner .banner-menu{top: 0px;}
.beneficios-banner h1{font-size: 40px;line-height: 49px;font-weight: 700;text-transform: uppercase;margin: 15px 0px;}
/*Form*/
::-webkit-input-placeholder {color: #485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
:-ms-input-placeholder {color: #485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
::placeholder {color:#485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
#todos-beneficios form{display: grid; grid-template-columns: 1fr 1fr 0.3fr; gap: 30px; padding: 54px 0px;}
#todos-beneficios form input, #todos-beneficios form select{font-size: 25px; color: #485860; font-family: 'Montserrat', sans-serif;
border: 0; border-bottom: 1px solid #485860; background-color: transparent;}
#todos-beneficios .form-button{background-color: transparent;border: 1px solid #485860;
color: #485860;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s;}
/*Conteúdo*/
.beneficios-load-content{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
  text-align: left; padding-bottom: 50px; }
.beneficios-load-content div{cursor:pointer; border: 1px solid #485860; color: #485860; padding: 20px 20px; text-transform: uppercase;}
.beneficios-load-content div p{font-size: 22px; font-weight: 700;}
.beneficios-load-content div span{font-size: 15px;}
.beneficios-load-content div:hover {border: 1px solid #6ba968;color: #6ba968;}
.beneficios-load-content div img {max-width: 200px; margin: 0 auto; display: block;  -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%);}
.beneficios-load-content div:hover img {-webkit-filter: grayscale(0%);-moz-filter: grayscale(0%);-o-filter: grayscale(0%);}
#todos-beneficios .container {text-align: center;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .banner{max-width: 1400px;}
  .beneficios-load-content div p {font-size: 18px;}
  .beneficios-load-content div span {font-size: 15px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
  .banner{max-width: 1200px;}
  .beneficios-load-content div p {font-size: 16px;}
  .beneficios-load-content div span {font-size: 14px;}
}
@media(max-width: 1199px){
  .banner{max-width: 100%; margin: 0 10px;}
  .beneficios-load-content div p {font-size: 16px;}
  .beneficios-load-content div span {font-size: 14px;}
}
@media (max-width: 767px){
  .beneficios-banner{grid-template-columns: 1fr; text-align: center;}
  .beneficios-load-content{grid-template-columns: 1fr}
  #todos-beneficios form{grid-template-columns: 1fr;}
  #todos-beneficios form .form-button {width: 100%;}
}

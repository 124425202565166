#servicos-page{padding: 44px 0px; position: relative;}
.servicos-banner{color:#485860; font-family: 'Montserrat', sans-serif; display: grid; grid-template-columns: 0.766fr 1fr;
align-items: center;}
.servicos-banner .banner-menu{top: 0px;}
.servicos-banner h1{font-size: 40px;line-height: 49px;font-weight: 700;text-transform: uppercase;margin: 15px 0px;}
/*Servicos*/
#servicos{padding: 50px 0px 0px 0px;}
/*Responsivo*/
@media (min-width: 1200px) and (max-width: 1399px){
  .accordion-list div{font-size: 18px;}
  .accordion-list li b{font-size: 19px;}
}
@media(max-width: 1199px){
  .accordion-list div{font-size: 16px;}
  .accordion-list li b{font-size: 17px;}
}
@media (max-width: 767px){
  .servicos-banner{grid-template-columns: 1fr; text-align: center;}
}

#cinema-page{ background-image: url('../../assets/Cinema/cinema_banner.jpg'); background-size: cover;
  background-position: center top; background-repeat: no-repeat;}
#cinema-programacao-page{ background-image: url('../../assets/Home/banner_1.jpg'); background-size: cover;
  background-position: center top; background-repeat: no-repeat;}
.cinema-banner{height: 70vh; color: #fff; font-family: 'Montserrat', sans-serif;}
.cinema-filter{height: 100%; background: rgba(0,0,0,0.6);}
.banner{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
    padding-bottom: 80px;}
.banner span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.cinema-page-content{display: grid; grid-template-columns: 1fr 1fr; gap: 12px; padding: 20px 0px;}
.cinema-page-content p{max-width: 694px; font-size: 22px; line-height: 27px;}
.cinema-page-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #fff; color: #fff;
text-transform: uppercase; font-size: 20px; line-height: 24px; width: 350px; height: 62px; border-radius: 0px; transition: background-color 0.2s;}
.cinema-page-content button:hover{background-color: #fff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.slide-icon{position: relative; left: 17px; top: 5px;}
.shopping-info{display: flex; justify-content: flex-end; gap: 29px;}
.shopping-info p{font-size: 20px; line-height: 24px;}
.shopping-info span{border-left: 1px solid #fff; height: 40px;}
.shopping-info a{color: #ffffff;}
/*Página Programação*/
#programacao{padding: 60px 0px;}
.filmes-content{display: grid; grid-template-columns: repeat(5, 1fr); gap: 30px; font-family: 'Montserrat', sans-serif;}
.filmes-content .titulo, .erro{font-family: 'Montserrat', sans-serif; color: #485860;font-size: 20px; font-weight: 700; line-height: 25px; text-transform: uppercase; height: 70px; padding: 10px 0px;}
.filmes-content .link{color: #ff6d6d;text-transform: uppercase;text-align: left;font-size: .8rem;font-weight: 700;
margin-top: .6rem;border: 2px solid #ff6d6d;padding: .3rem 1rem;display: inline-block;}
.filmes-content .genero{text-transform: uppercase; color: #485860; display: flex; justify-content: space-between;}
.filmes-content .classificacao{background-color: #207ddd; color: #ffffff; font-weight: 700; padding: 5px;}
.cinema-box p{max-width: 100% !important; padding: 0px 30px 20px 30px;}
.cinema-img{object-fit: cover; height: 800px;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.cinema-page-content p {font-size: 16px;}
.shopping-info p {font-size: 20px;line-height: 24px;}
.cinema-img{object-fit: cover;height: 840px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1200px;}
.banner h1{font-size: 50px; line-height: 60px;}
.cinema-page-content p {font-size: 16px;}
.shopping-info p {font-size: 20px;line-height: 24px;}
.cinema-img{object-fit: cover;height: 890px;} 
}
@media(max-width: 1199px){
.banner{max-width: 100%; margin: 0 10px;}
.banner h1{font-size: 2em; line-height: 23px;}
.cinema-page-content p {font-size: 16px;}
.shopping-info p {font-size: 20px;line-height: 24px;}
.cinema-img{object-fit: cover;height: 72vh !important;} 
.life-center-content{grid-template-columns: 1fr;}
}

@media (min-width: 768px) and (max-width: 991px){
  .filmes-content .titulo, .erro{font-size: 15px; line-height: 18px; height: 60px;}
  .filmes-content .genero{font-size: 14px;}
}

@media (max-width: 767px){
  .banner{padding-bottom: 50px;}
  .banner button {width: 100%;height: 45px;font-size: 14px;}
  .cinema-page-content{grid-template-columns: 1fr;}
  .filmes-content{grid-template-columns: 1fr;}
  .banner hr{margin-bottom: 0px;}
  .filmes-content .titulo, .erro{font-size: 15px; line-height: 18px; height: 60px;}
  .filmes-content .genero{font-size: 14px;}
}
#lojas-page{ background-image: url('../../assets/Lojas/lojas_banner.jpg'); background-size: cover;
background-position: center top; background-repeat: no-repeat;}
.lojas-banner{height: 70vh; color: #fff; font-family: 'Montserrat', sans-serif;}
.lojas-filter{height: 100%; background: rgba(0,0,0,0.6);}
.banner{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
  padding-bottom: 80px;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.lojas-banner .shopping-info{justify-content: flex-start; gap: 12px;}
.lojas-banner .shopping-info a{color: #ffffff;}
.banner-icon{position: relative; left: 0px; top: 5px;}
/*Form*/
#todas-lojas form input::-webkit-input-placeholder {color: #485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
#todas-lojas form input:-ms-input-placeholder {color: #485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
#todas-lojas form input::placeholder {color:#485860; font-size: 25px; font-family: 'Montserrat', sans-serif;}
#todas-lojas form{display: grid; grid-template-columns: 1fr 1fr 0.3fr; gap: 30px; padding: 54px 0px;}
#todas-lojas form input, #todas-lojas form select{font-size: 25px; color: #485860; font-family: 'Montserrat', sans-serif;
border: 0; border-bottom: 1px solid #485860; background-color: transparent;}
#todas-lojas .form-button{background-color: transparent;border: 1px solid #485860;
color: #485860;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s;}
/*Conteúdo*/
.lojas-load-content{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
  text-align: left; padding-bottom: 50px; }
.lojas-load-content div{border: 1px solid #485860; color: #485860; padding: 20px 20px; text-transform: uppercase; cursor: pointer;}
.lojas-load-content div:hover{border: 1px solid #6ba968; color: #6ba968;}
.lojas-load-content div p{font-size: 20px; font-weight: 700;}
.lojas-load-content div span{font-size: 15px;}
.lojas-load-content div:hover img{-webkit-filter: grayscale(0%); -moz-filter: grayscale(0%); -o-filter: grayscale(0%);}
.logo_loja {height: 18em; border: none !important; padding: 0px !important; align-items: center; display: flex;}
.logo_loja img {max-width: 200px; margin: 0 auto; display: block;  -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%);}
.autocomplete{font-family: 'Montserrat', sans-serif; text-align: left; color: #485860; font-size: 18px; border: 1px solid #485860;
padding: 0px; position: relative; top: -31px;}
.autocomplete div span{padding-left: 5px;}
.autocomplete div:hover{background-color: #485860; color: #ffffff; cursor: pointer;}
#todas-lojas .container {text-align: center;}
/*Modal*/
.modal-bg{position: fixed;top: 0;right: 0;bottom: 0;left: 0; background: #00000066; z-index: 99999;
display: none;}
.modal{position: relative; top: 50px; width: 1000px; height: 600px; background: #ffffff; border: 1px solid #000; margin: 0 auto;}
.show-modal{display: block;}
.modal-content{display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; 
  color: #485860; font-family: 'Montserrat', sans-serif; align-items: center; height: 100%;}
.modal-content .logo{text-align: center;}
.modal-content img{max-width: 230px; margin: 0 auto;}
.modal-content .info{text-align: left;}
.modal-content .info .categoria{max-width: 400px;}
.modal-content .info a{color: #485860;}
.modal-icon{cursor: pointer; position: absolute; right: 10px; top: 10px;}
.modal-redes-sociais{display: flex;align-items: center;}
.modal-redes-sociais li{display: inline-block; padding-right: 10px;}
.modal-redes-sociais li a {width: 25px; height: 25px; display: block;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.lojas-load-content div p {font-size: 18px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1200px;}
.banner h1{font-size: 50px; line-height: 60px;}
.lojas-load-content div p {font-size: 16px;}
}
@media(max-width: 1199px){
.banner{max-width: 100%; margin: 0 10px;}
.banner h1{font-size: 2em; line-height: 23px;}
.lojas-load-content div p {font-size: 16px;}
.lojas-load-content div span {font-size: 12px;}
}
@media (min-width: 768px) and (max-width: 991px){
.lojas-load-content{grid-template-columns: repeat(2, 1fr);}
#todas-lojas form{grid-template-columns: 1fr;}
#todas-lojas .form-button{justify-self: center;}
.modal{width: 700px;}
.modal-content h1{font-size: 20px;}
.modal-content p{font-size: 16px;}
}
@media (max-width: 767px){
#todas-lojas form{grid-template-columns: 1fr;}
.lojas-load-content{grid-template-columns: 1fr;}
#todas-lojas form input, #todas-lojas form select{width: 100%;}
#todas-lojas .form-button {width: 100%;}
.lojas-banner {height: 89vh;}
.modal-content{grid-template-columns: 1fr; gap: 10px; height: 90%;}
.modal{width: 90%;}
.modal-content .logo{max-width: 230px; margin: 0 auto;}
.modal-content .info .categoria {max-width: 100%;}
.modal-content h1{font-size: 18px;}
.modal-content p{font-size: 16px;}
.modal-content .info {text-align: center;padding: 0px 20px;}
}

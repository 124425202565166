#tem-no-parque-page{ background-image: url('../../assets/Tem-no-Parque/sustentabilidade_banner.jpg'); background-size: cover;
  background-position: center top; background-repeat: no-repeat;}
#tem-no-parque-life-center-page{ background-image: url('../../assets/Tem-no-Parque/lifecenter_banner.jpeg'); background-size: cover;
  background-position: center top; background-repeat: no-repeat;}
#tem-no-parque-sanitario-page{ background-image: url('../../assets/Tem-no-Parque/sanitario_banner.jpg'); background-size: cover;
    background-position: center top; background-repeat: no-repeat;}
#tem-no-parque-atlantica-page{ background-image: url('../../assets/Tem-no-Parque/mata_atlantica_banner.jpg'); background-size: cover;
      background-position: center top; background-repeat: no-repeat;}
#tem-no-parque-telhado-verde-page{ background-image: url('../../assets/Tem-no-Parque/telhado_verde_banner.jpg'); background-size: cover;
        background-position: center top; background-repeat: no-repeat;}
#tem-no-parque-coleta-lixo-page{ background-image: url('../../assets/Tem-no-Parque/coleta_lixo_banner.jpg'); background-size: cover;
          background-position: center top; background-repeat: no-repeat;}
#tem-no-parque-clinica-page{ background-image: url('../../assets/Tem-no-Parque/clinica_banner.jpg'); background-size: cover;
            background-position: center top; background-repeat: no-repeat;}
.tem-no-parque-banner{height: 70vh; color: #fff; font-family: 'Montserrat', sans-serif;}
.tem-no-parque-filter{height: 100%; background: rgba(0,0,0,0.6);}
.banner{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
    padding-bottom: 80px;}
.banner span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner p{max-width: 786px;font-size: 22px; line-height: 27px;}
#tem-no-parque{padding: 32px 0px;}
.tem-no-parque-content{display: grid; grid-template-columns: 1fr 1fr; gap: 10px; align-items: center; 
  text-align: left; justify-items: end; font-family: 'Montserrat', sans-serif;}
.tem-no-parque-content p{color: #485860; font-size: 18px; line-height: 25px; padding-bottom: 20px;}
/*box*/
.tem-no-parque-box-filter{height: 100%; background: rgba(0,0,0,0.2);}
.tem-no-parque-box-content{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;}
.tem-no-parque-box-content div{height: 320px; background-repeat: no-repeat;}
.tem-no-parque-box-content .card {max-width: 470px; display: flex; flex-direction: column; align-items: self-end; justify-content: flex-end; padding-left: 20px; }
.tem-no-parque-box-content h1{font-size: 30px; line-height: 60px; font-weight: 700; text-transform: uppercase; color: #fff;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p{font-size: 16px;}
.tem-no-parque-content p{font-size: 16px;}
.tem-no-parque-content img{object-fit: cover;height: 600px;}
.tem-no-parque-box-content div {height: 33vh;background-size: cover;}
.tem-no-parque-box-content .card {padding-left: 10px;max-width: 100%;}
.tem-no-parque-box-content h1 {font-size: 23px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1200px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p{font-size: 16px;}
.tem-no-parque-content p{font-size: 16px;}
.tem-no-parque-content img{object-fit: cover;height: 650px;}
.tem-no-parque-box-content div {height: 33vh;background-size: cover;}
.tem-no-parque-box-content .card {padding-left: 10px;max-width: 100%;}
.tem-no-parque-box-content h1 {font-size: 20px;}
}
@media(max-width: 1199px){
.banner{max-width: 100%; margin: 0 10px;}
.banner h1{font-size: 2em; line-height: 23px;}
.banner p{font-size: 16px;}
.tem-no-parque-box-content{grid-template-columns: repeat(2, 1fr);}
.tem-no-parque-content p{font-size: 16px;}
.tem-no-parque-content img{object-fit: cover;height: 120vh;}
.tem-no-parque-box-content div {height: 53vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;}
.tem-no-parque-box-content .card {padding-left: 10px;max-width: 100%;}
.tem-no-parque-box-content h1 {font-size: 2em; line-height: 50px;}
}

@media (max-width: 991px){
  .tem-no-parque-content{grid-template-columns: 1fr;}
  .tem-no-parque-content img{display: none;}
}

@media (max-width: 767px){
  .tem-no-parque-box-content{grid-template-columns: 1fr;}
  .tem-no-parque-box-content h1 {font-size: 1.3em;line-height: 30px;}
  .tem-no-parque-box-content div {height: 50vh !important;}
}
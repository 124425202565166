.slide-filter{height: 100%; background: rgba(0,0,0,0.6);}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px;}
.slide1{ background-image: url('../../assets/Home/banner_1.jpg'); background-size: cover;
background-position: center top; background-repeat: no-repeat;}
.slide2{ background-image: url('../../assets/Home/banner_2.jpg'); background-size: cover;
  background-position: center bottom; background-repeat: no-repeat;}
.slide3{ background-image: url('../../assets/Home/banner_3.jpg'); background-size: cover;
    background-position: center bottom; background-repeat: no-repeat;}  
.slide4{ background-image: url('../../assets/Home/banner_4.jpg'); background-size: cover;
      background-position: center center; background-repeat: no-repeat;}
.swiper-slide{height: 70vh; color: #fff; font-family: 'Montserrat', sans-serif;}
.slides{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
  padding-bottom: 50px;}
.swiper-slide span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.swiper-slide h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.slide-content{display: grid; grid-template-columns: 1fr 1fr; gap: 12px; padding: 20px 0px;}
.slide-content p{max-width: 694px; font-size: 22px; line-height: 27px;}
.slide-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #fff; color: #fff;
text-transform: uppercase; font-size: 20px; line-height: 24px; width: 350px; height: 62px; border-radius: 0px; transition: background-color 0.2s;}
.slide-content button:hover a{background-color: #fff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.slide-content button a{display: flex;height: 62px;text-align: center;align-items: center;justify-content: center; color: #ffffff;}
.slide-icon{position: relative; left: 17px; top: 5px;}
.shopping-info{display: flex; justify-content: flex-end; gap: 29px;}
.shopping-info p{font-size: 20px; line-height: 24px;}
.shopping-info span{border-left: 1px solid #fff; height: 40px;}
.swiper-pagination-bullet-active{background: #ffffff !important;}
.swiper-pagination-bullet{background: #ffffff !important;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .swiper-slide {height: 70vh;}
  .slide-content{grid-template-columns: 1fr 1fr;}
  .swiper-slide h1 {font-size: 50px; line-height: 60px;}
  .slide-content p {max-width: 568px; font-size: 16px;}
  .slide-icon{left: 0px}
  .slides{max-width: 1400px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
  .swiper-slide h1{font-size: 30px;}
  .swiper-slide {height: 70vh;}
  .slide-content{grid-template-columns: 1fr 1fr;}
  .swiper-slide h1 {font-size: 50px; line-height: 60px;}
  .slide-content p {max-width: 568px; font-size: 16px;}
  .slide-icon{left: 0px}
  .slides{max-width: 1200px;}
  .shopping-info{gap: 17px;}
}

@media(max-width: 1199px){
  .slides{max-width: 100%; margin: 0 10px;}
  .slide-icon{left: 0px}
}

@media (min-width: 992px) and (max-width: 1199px){
  .swiper-slide  h1{font-size: 2em; line-height: 23px;}
  .slide-content{grid-template-columns: 1fr 1fr;}
  .slide-content p {max-width: 100%;font-size: 16px;}
  .shopping-info{gap: 24px;}
}

@media (min-width: 768px) and (max-width: 991px){
  .swiper-slide  h1{font-size: 2em; line-height: 23px;}
  .slide-content{grid-template-columns: 1fr 1fr;}
  .slide-content p {max-width: 100%;font-size: 16px;}
  .slide-content button{width: 240px; font-size: 15px;}
  .shopping-info{gap: 8px;}
}

@media (max-width: 767px){
  .slider{padding-bottom: 40px;}
  .slides {margin: 0 30px;}
  .swiper-slide{height: 89vh;}
  .swiper-slide span{font-size: 14px;}
  .swiper-slide h1 {font-size: 1.3em; line-height: 30px; margin: 5px 0px;}
  .slide-content {grid-template-columns: 1fr;}
  .slide-content p {max-width: 100%; font-size: 14px; line-height: 25px; margin: 0px;}
  .slide-content button {width: 100%; height: 45px; font-size: 14px;}
  .shopping-info {display: flex;justify-content: space-between; gap: 0px;}
  .shopping-info p {font-size: 14px; margin: 0; line-height: 19px;}
  .slide-icon{width: 28px; top: 0px;}
  .shopping-info{display: none !important;}
  .slide-content button a{height: 43px;}
}


@media (max-width: 320px){
  .shopping-info{gap: 17px;}
  .shopping-info p {font-size: 11px; margin: 0; line-height: 19px;}
}
footer{padding: 60px 0px 0px 0px; position: relative; width: 100%; bottom: 0;}
.logo_footer{width: 267px; height: auto; position: relative; top: -35px; background-color: #ffffff;}
.footer-content{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
color: #000000; padding: 60px 0px;}
.footer-content .title{font-size: 16px; color: #6ba968; text-transform: none;}
.footer-content p {font-size: 16px; color: #000000; padding: 10px 0px; line-height: 20px;}
.footer-content span{text-transform: uppercase;}
.footer-content ul {padding: 10px 0px;}
.footer-content ul li a{line-height: 20px; font-size: 16px; color: #000000;}
.footer-content ul li a:hover{color: #6ba968;}

.footer_icone{position: relative; top: 2px;}
.footer-content2{display: flex; gap: 20px; font-family: 'Montserrat', sans-serif; color: #000000; align-items: center; justify-content: center;}
.footer-content2 p {font-size: 14px; color: #000000; padding: 10px 0px; line-height: 20px;}
.logo-ena{justify-self:right}

@media (min-width: 1400px) and (max-width: 1599px){
.footer-content{gap: 90px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
.footer-content{gap: 20px; grid-template-columns: 300px 300px 198px 300px;}
.footer-content p , .footer-content .title .footer-content ul li{font-size: 15px;}
}

@media (min-width: 768px) and (max-width: 991px){
.footer-content{grid-template-columns: repeat(2, 1fr);}
}


@media (max-width: 767px){
.logo_footer{width: 200px;}
.footer-content{grid-template-columns: 1fr; padding: 0px;}
.footer-content div{margin-bottom: 20px;}
.footer-content p, .footer-content .title, .footer-content span, .footer-content ul li a, .footer-content2 p {font-size: 13px;}
.footer_icone{top: 6px;}
.footer-content2{display: grid; grid-template-columns: 1fr; gap: 7px; text-align: center;}
.logo-ena {justify-self: center; height: 45px;}
}

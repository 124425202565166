#clube-do-parque-page{ background-image: url('../../assets/Clube-do-Parque/clube_banner.jpg'); background-size: cover;
  background-position: center top; background-repeat: no-repeat;}
.clube-do-parque-banner{height: 70vh; color: #fff; font-family: 'Montserrat', sans-serif;}
.clube-do-parque-filter{height: 100%; background: rgba(0,0,0,0.6);}
.banner{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
    padding-bottom: 80px;}
.banner span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner p{max-width: 786px;font-size: 22px; line-height: 27px;  margin-bottom: 20px;}
.banner button {margin: 20px 0px; background-color: transparent; border: 1px solid #fff; color: #fff;
  text-transform: uppercase; font-size: 20px; line-height: 24px; width: 350px; height: 62px; border-radius: 0px; transition: background-color 0.2s;}
.banner button:hover a{background-color: #fff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.banner button a{display: flex;height: 62px;text-align: center;align-items: center;justify-content: center; color: #ffffff;}
/*Beneficios*/
#beneficios{padding: 32px 0px;}
#beneficios p{font-size: 20px; line-height: 35px; color: #485860; font-family: 'Montserrat', sans-serif; margin-bottom: 30px;}
.beneficios-content-grid{display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px; align-items: flex-start; font-family: 'Montserrat', sans-serif;}
.beneficios-content-grid .bg{background-color: #BED6BB; text-align: center; padding: 20px; width: 100%;}
.beneficios-content-grid .bg p{font-size: 18px; line-height: 21px; padding: 10px 0px;}
.beneficios-content-grid .bg a{font-weight: 700; color: #485860;}
/*Confira*/
#confira h1{color:#485860; font-family: 'Montserrat', sans-serif; font-size: 20px; line-height: 25px; font-weight: 700;
text-transform: uppercase; margin-bottom: 20px;}
.confira-content{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;
  text-align: left; padding-bottom: 50px; }
.confira-content div{border: 1px solid #485860; color: #485860; padding: 20px 20px; text-transform: uppercase;}
.confira-content div p{font-size: 22px; font-weight: 700;}
.confira-content div span{font-size: 16px; text-transform: none;}
.logo_clube {height: 18em; border: none !important; padding: 0px !important; align-items: center; display: flex;}
.logo_clube img {max-width: 200px; margin: 0 auto; display: block;  -webkit-filter: grayscale(0%); -moz-filter: grayscale(0%); -o-filter: grayscale(0%);}
.modal-icon-x {cursor: pointer;position: absolute;right: 30px; top: 10px; z-index: 99999}


/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p {font-size: 16px;}
.confira-content div p {font-size: 18px;}
.confira-content div span {font-size: 15px;}
}
@media (min-width: 1200px) and (max-width: 1399px){
.banner{max-width: 1200px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p {font-size: 16px;}
.confira-content div p {font-size: 16px;}
.confira-content div span {font-size: 15px;}
#confira h1{font-size: 17px;}
.beneficios-content-grid{align-items: center;}
#beneficios p {font-size: 16px;}
.beneficios-content-grid .bg p {font-size: 15px; line-height: 18px;}
}
@media(max-width: 1199px){
.banner{max-width: 100%; margin: 0 10px; padding-bottom: 50px}
.banner h1{font-size: 2em; line-height: 35px;}
.banner p {font-size: 16px;}
.confira-content div p {font-size: 16px;}
.confira-content div span {font-size: 14px;}
#confira h1{font-size: 16px;}
#beneficios p {font-size: 15px;}
.beneficios-content-grid .bg p {font-size: 15px; line-height: 18px;}
.beneficios-content-grid{grid-template-columns: 1fr }
}

@media (min-width: 992px) and (max-width: 1199px){
  .beneficios-content-grid .bg p {font-size: 14px; line-height: 18px;}
}

@media (max-width: 767px){
.banner span {font-size: 14px;}
.banner button {width: 100%;height: 45px;font-size: 14px;}
.confira-content{grid-template-columns: 1fr;}
.beneficios-content-grid{grid-template-columns: 1fr }
.banner button a{height: 43px;}
}
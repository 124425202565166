/*Life-center*/
.life-center-content{display: grid; grid-template-columns: 1fr 1fr; gap: 10px; align-items: center; 
 text-align: center; justify-items: center; font-family: 'Montserrat', sans-serif;}
.life-center-content h2{color: #485860; font-size: 45px; line-height: 50px; font-weight: 400; text-transform: uppercase; padding-bottom: 20px;}
.life-center-content p{color: #485860; font-size: 18px; line-height: 25px; max-width: 561px; padding-bottom: 20px;}
.life-center-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #485860; color: #485860;
  text-transform: uppercase; font-size: 21px; line-height: 25px; width: 207px; height: 60px; border-radius: 0px; transition: background-color 0.2s;}
.life-center-content button:hover {background-color: #ffffff; color: #485860; border: 1px solid #485860;}
.life-center-content button a{display: flex;height: 62px;text-align: center;align-items: center;justify-content: center; color: #485860;}
/*Cinema*/
#cinema{padding-top: 0px; margin-top: 10px;}
.cinema-content{font-family: 'Montserrat', sans-serif; height: 640px;}
.filter{height: 100%; background: rgba(0,0,0,0.4);}
.filmes{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
  padding-bottom: 50px;}
.cinema-content .container{width: 1600px; position: relative; top: 170px;}
.cinema-content span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.cinema-content h1 {font-size: 60px; line-height: 60px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.cinema-content p {max-width: 1091px; font-size: 19px; line-height: 25px; }
.cinema-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #ffffff; color: #ffffff;
  text-transform: uppercase; font-size: 22px; line-height: 27px; width: 354px; height: 62px; border-radius: 0px; transition: background-color 0.2s;}
.cinema-content button:hover a{background-color: #ffffff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.cinema-content button a{display: flex;height: 62px;text-align: center;align-items: center;justify-content: center; color: #ffffff;}
/*Novidades*/
.novidades_filter{height: 100%; background: rgba(0,0,0,0.4);}
.novidades-content{display: grid; grid-template-columns: 1fr 1fr; gap: 10px; font-family: 'Montserrat', sans-serif;}
.novidades-content div{height: 640px; background-repeat: no-repeat;}
.novidades-content .card {max-width: 690px; display: flex; flex-direction: column; align-items: self-end; justify-content: flex-end; padding-left: 50px; padding-bottom: 50px;}
.novidades-content span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.novidades-content h1{font-size: 48px; line-height: 60px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.novidades-content p{color: #ffffff; font-size: 19px; line-height: 25px; padding-bottom: 20px; height: 76px;}
.novidades-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #ffffff; color: #ffffff;
text-transform: uppercase; font-size: 22px; line-height: 27px; width: 207px; height: 60px; border-radius: 0px; transition: background-color 0.2s;}
.novidades-content button:hover a{background-color: #ffffff; color: #485860; border: 1px solid #ffffff;}
.novidades-content button a{display: flex;height: 62px;text-align: center;align-items: center;justify-content: center; color: #ffffff;}
/*Nossas Lojas*/
#lojas{padding-top: 0px; margin-top: 10px;}
.lojas{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
  padding-bottom: 50px;}
.lojas-content{font-family: 'Montserrat', sans-serif; height: 320px;}
.lojas-content .container{width: 1600px;}
.lojas-content h1 {font-size: 60px; line-height: 60px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.lojas-content p {max-width: 735px; font-size: 19px; line-height: 25px;}
.lojas-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #ffffff; color: #ffffff;
  text-transform: uppercase; font-size: 22px; line-height: 27px; width: 207px; height: 60px; border-radius: 0px; transition: background-color 0.2s;}
.lojas-content button:hover a{background-color: #ffffff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
.lojas-content button a{display: flex;height: 62px;text-align: center;align-items: center;justify-content: center; color: #ffffff;}
/*Diferenciais*/
.diferenciais_filter{height: 100%; background: rgba(0,0,0,0.2);}
.diferenciais-content{display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; font-family: 'Montserrat', sans-serif;}
.diferenciais-content div{height: 320px; background-repeat: no-repeat;}
.diferenciais-content .card {max-width: 470px; display: flex; flex-direction: column; align-items: self-end; justify-content: flex-end; padding-left: 50px; }
.diferenciais-content h1{font-size: 30px; line-height: 60px; font-weight: 700; text-transform: uppercase;}
.diferenciais-content p{color: #ffffff; font-size: 19px; line-height: 25px; padding-bottom: 20px; height: 76px;}
.diferenciais-content a{color: #ffffff;}
/*Newsletter*/
#newsletter{padding-top: 0px; margin-top: 10px; background-color: #edf5ed;}
.newsletter-content{text-align: center; font-family: 'Montserrat', sans-serif; padding: 35px 0px;}
.news-icone {margin-bottom: 10px;}
.newsletter-content p {color: #485860; font-weight: 400; font-size: 16px}
.newsletter-content button {margin: 20px 0px; background-color: #6ba968; border: 1px solid #6ba968; color: #ffffff;
  text-transform: uppercase; font-size: 14px; line-height: 18px; width: 120px; height: 38px; border-radius: 0px; transition: background-color 0.2s;}
.newsletter-content button:hover{background-color: #679665; color: #ffffff; border: 1px solid #679665; font-weight: 400;}
/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .life-center-content h2{font-size: 40px;}
  .life-center-content p{font-size: 16px; max-width: 471px;}
  .cinema-content p, .novidades-content p, .lojas-content p, .diferenciais-content p{font-size: 16px;}
  .filmes, .lojas{max-width: 1400px;}
  .cinema-content{height: 53vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .cinema-content .container{width: 1400px;} 
  .cinema-content h1, .novidades-content h1, .lojas-content h1 {font-size: 30px;}
  .novidades-content div {height: 53vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .lojas-content{height: 33vh; background-size: cover;
      background-position: center center; background-repeat: no-repeat;}
  .diferenciais-content h1 {font-size: 23px;}
  .diferenciais-content .card{padding-left: 10px; max-width: 100%;}
  .diferenciais-content div {height: 33vh; background-size: cover;}
  .diferenciais-content p{padding-bottom: 0px; height: 76px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
  .life-center-content h2{font-size: 40px;}
  .life-center-content p{font-size: 16px; max-width: 471px;}
  .cinema-content p, .novidades-content p, .lojas-content p, .diferenciais-content p{font-size: 16px;}
  .novidades-content .card{padding-bottom: 20px;}
  .filmes, .lojas{max-width: 1200px; padding-bottom: 20px;}
  .lojas{padding-bottom: 20px;}
  .cinema-content{height: 53vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .cinema-content .container, .lojas-content .container{width: 1200px;}
  .cinema-content h1, .novidades-content h1, .lojas-content h1 {font-size: 30px;}
  .novidades-content div {height: 53vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .lojas-content{height: 33vh; background-size: cover;
      background-position: center center; background-repeat: no-repeat;}
  .diferenciais-content h1 {font-size: 20px;}
  .diferenciais-content .card{padding-left: 10px; max-width: 100%;}
  .diferenciais-content div {height: 33vh; background-size: cover;}
  .diferenciais-content p{padding-bottom: 0px; height: 76px;}
  .newsletter-content p{font-size: 15px;}
}

@media(max-width: 1199px){
  .filmes, .lojas{max-width: 100%; margin: 0 10px; padding-bottom: 0px;}
}

@media (min-width: 992px) and (max-width: 1199px){
  .life-center-content h2 {font-size: 2em; line-height: 37px;}
  .life-center-content p {font-size: 16px; line-height: 21px; max-width: 98%;}
  .life-center-content img{object-fit: cover; width: 100%; height: 58vh;}
  .cinema-content, .novidades-content div, .lojas-content, .diferenciais-content div {height: 53vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .cinema-content h1, .novidades-content h1, .lojas h1 {font-size: 2em;line-height: 23px;}
  .cinema-content p, .novidades-content p, .lojas p, .diferenciais-content p {max-width: 100%; font-size: 16px; line-height: 27px;}
  .novidades-content .card, .diferenciais-content .card{max-width: 100%; padding: 0px 10px;}
  .diferenciais-content{grid-template-columns: repeat(2, 1fr);}
  .diferenciais-content h1 {font-size: 2em; line-height: 50px;}
}

@media (min-width: 768px) and (max-width: 991px){
  .life-center-content {grid-template-columns: 1fr;}
  .life-center-content h2 {font-size: 2em; line-height: 37px;}
  .life-center-content p {font-size: 16px; line-height: 21px; max-width: 80%; margin: 0 auto;}
  .cinema-content, .novidades-content div, .lojas-content, .diferenciais-content div {height: 53vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .cinema-content h1, .novidades-content h1, .lojas h1 {font-size: 2em;line-height: 23px;}
  .cinema-content p, .novidades-content p, .lojas p, .diferenciais-content p {max-width: 100%; font-size: 16px; line-height: 27px;}
  .novidades-content .card, .diferenciais-content .card{max-width: 100%; padding: 0px 10px;}
  .diferenciais-content{grid-template-columns: repeat(2, 1fr);}
  .diferenciais-content h1 {font-size: 1.8em; line-height: 50px;}
  .life-center-content button{width: 147px; font-size: 15px;}
  .cinema-content button {width: 240px;font-size: 15px;}
  .novidades-content p {height: 106px;}
}

@media (max-width: 767px){
  .life-center-content, .novidades-content, .diferenciais-content {grid-template-columns: 1fr;}
  .life-center-content h2{font-size: 1.4em; line-height: 28px;}
  .life-center-content p {font-size: 14px; padding-bottom: 10px;}
  .life-center-content button, .novidades-content button{width: 96%; height: 45px; font-size: 14px;}
  .cinema-content span, .novidades-content span{font-size: 14px;}
  .cinema-content h1, .novidades-content h1, .lojas-content h1 {font-size: 1.3em; line-height: 30px; margin: 5px 0px;}
  .diferenciais-content h1 {font-size: 1.3em; line-height: 30px;}
  .cinema-content p, .novidades-content p, .lojas-content p, .diferenciais-content p, .newsletter-content p {font-size: 14px; padding-bottom: 0px;}
  .cinema-content button, .lojas-content button{width: 100%; height: 45px; font-size: 14px;}
  .cinema-content, .novidades-content div, .lojas-content, .diferenciais-content div {height: 100vh; background-size: cover;
    background-position: center center; background-repeat: no-repeat;}
  .novidades-content div, .lojas-content, .diferenciais-content div{height: 50vh !important;}
  .novidades-content .card, .diferenciais-content .card {padding-left: 0px; padding-bottom: 0px; margin: 0 30px;}
  .novidades-content p {height: auto;}
  .diferenciais-content p{height: 47px;}
  .life-center, .filmes, .lojas{margin: 0 30px;}
  .life-center-content button a, .cinema-content button a, .novidades-content button a,
  .lojas-content button a{height: 43px;}
  

}

#contato-page{padding: 44px 0px; position: relative;}
.contato-banner{color:#485860; font-family: 'Montserrat', sans-serif; display: grid; grid-template-columns: 0.766fr 1fr;
align-items: center;}
.contato-banner .banner-menu{top: 0px;}
.contato-banner h1{font-size: 40px;line-height: 49px;font-weight: 700;text-transform: uppercase;margin: 15px 0px;}
/*FORM*/
#form-contato{padding: 50px 0px 0px 0px;}
.form-contato-content{display: grid; grid-template-columns: 1.4fr 0.01fr 1fr; gap: 25px; font-family: 'Montserrat', sans-serif; padding-bottom: 40px;}
.form-contato-content h1, .form-contato-content strong, .form-contato-content div{color: #818486; font-size: 20px; line-height: 24px;}
::-webkit-input-placeholder {color: #818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
:-ms-input-placeholder {color: #818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
::placeholder {color:#818486; font-size: 16px; font-family: 'Montserrat', sans-serif;}
.modal-content-contato select{color:#818486; font-size: 16px !important; font-family: 'Montserrat', sans-serif; margin-bottom: 30px !important;}
#form-contato form{padding: 54px 0px 0px 0px;}
#form-contato form input, #form-contato form select,
.modal-content-contato input, .modal-content-contato select{font-size: 25px; color: #818486; font-family: 'Montserrat', sans-serif;
border: 0; border-bottom: 1px solid #818486; background-color: transparent; width: 100%; margin-bottom: 48px;}
.modal-content-contato input, .modal-content-contato select{margin-bottom: 10px;}
.modal-content-contato span{font-size: 15px; margin-bottom: 48px; display: block;}
#form-contato form select{font-size: 16px !important;}
#form-contato form textarea{font-size: 25px; color: #818486; font-family: 'Montserrat', sans-serif; border: 1px solid #818486;
width: 100%; height: 150px; margin-bottom: 20px;}
label{font-size: 16px; color: #818486;}
#form-contato form .half{display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;}
.checkbox{font-size: 15px; color: #818486; margin-bottom: 20px;}
.checkbox input{width: 15px !important; height: 15px; margin-bottom: 20px !important;}
.checkbox span{padding-left: 5px; position: relative; top: 2px; font-size: 16px;}
#form-contato form .form-button{background-color: #63a36a;border: 1px solid #63a36a;
color: #ffffff;text-transform: uppercase;font-size: 21px;width: 207px;height: 60px;border-radius: 0px;
justify-self: flex-end; transition: background-color 0.2s;}
#form-contato form .form-button:hover{background-color: #2f6735; border: 1px solid #2f6735;}
.form-button-identifica{background-color: #63a36a;border: 1px solid #63a36a;
  color: #ffffff;text-transform: uppercase;font-size: 21px;width: 100%;height: 60px;border-radius: 0px;
  justify-self: flex-end; transition: background-color 0.2s; margin-bottom: 40px;}
.form-button-identifica:hover{background-color: #2f6735; border: 1px solid #2f6735;}
.form-width{width: 500px; margin: 0 auto;}
.form-width span{background: #fff;width: 30px;display: block;text-align: center; margin: 0 auto;
top: -13px; position: relative; font-size: 13px;}
.input-radios, .input-checkbox{padding: 10px 5px; margin-bottom: 28px;}
.input-checkbox{border: none !important;}
.input-radios div, .input-checkbox div{font-size: 17px !important;}
.input-radios input, .input-checkbox input{width: 20px !important; margin-bottom: 0px !important;}
.politicas{text-decoration: underline; cursor: pointer;}
.senha input{margin-bottom: 10px !important;}
.senha p{font-size: 16px; cursor: pointer; margin-bottom: 48px; color: #63a36a; text-decoration: underline; text-align: right;}
.senha p:hover{color: #2f6735; font-weight: bold;}
.contato-info iframe{border: none; width: 100%;}
.line{padding: 20px 0px;}
.line span{border-left: 1px solid #e8e8e8; height: 100%; display: block;}
.comercial{padding-bottom: 50px;}
.comercial h1{color: #818486; font-size: 20px; line-height: 24px; font-family: 'Montserrat', sans-serif; }
.comercial h2{color: #818486; font-size: 20px; line-height: 24px; font-family: 'Montserrat', sans-serif; }
.comercial strong, .comercial p, .comercial a{color: #485860;font-size: 20px;line-height: 25px;
max-width: 100%;padding-bottom: 20px;font-family: 'Montserrat', sans-serif;}
.news:checked:after {width: 15px !important;height: 15px !important;top: -9px !important;left: 0px !important;}

/*Modal*/
.modal-contato{position: relative; top: 50px; width: 500px; height: 500px; background: #ffffff; margin: 0 auto;}
.modal-content-contato{display: grid; grid-template-columns: 1fr; padding: 30px 10px; color: #485860; 
  font-family: 'Montserrat', sans-serif; align-items: center; height: 100%;}
.modal-content-contato h3{text-align: center;}
.check-icon{margin: 0 auto;}
.modal-contato-cadastro{height: 400px !important;}
.msg {display: flex; align-items: baseline; justify-content: space-between;}
.center{text-align: center;}

/*Responsivo*/
@media (min-width: 1200px) and (max-width: 1399px){
  .form-contato-content h1, .form-contato-content strong, .form-contato-content div, .comercial h1{font-size: 16px;}
  #form-contato form input, #form-contato form select, label {font-size: 16px;}
  ::-webkit-input-placeholder {font-size: 16px;}
  :-ms-input-placeholder {font-size: 16px;}
  ::placeholder {font-size: 16px;}
  .news:checked:after{top: 0px !important;}
}
@media (min-width: 992px) and (max-width: 1199px){
.form-width {width: 360px;}
#form-contato form input::-webkit-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
#form-contato form input:-ms-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
#form-contato form input::placeholder {color:#818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
.news:checked:after{top: 0px !important;}
}


@media(max-width: 1199px){
  .form-contato-content h1, .form-contato-content strong, .form-contato-content div, .comercial h1{font-size: 16px;}
  #form-contato form input, #form-contato form select, label {font-size: 16px;}
  .form-width {width: 300px;}
  .form-button-identifica{font-size: 16px;}
  #form-contato form input::-webkit-input-placeholder {color: #818486; font-size: 14px; font-family: 'Montserrat', sans-serif;}
  #form-contato form input:-ms-input-placeholder {color: #818486; font-size: 14px; font-family: 'Montserrat', sans-serif;}
  #form-contato form input::placeholder {color:#818486; font-size: 14px; font-family: 'Montserrat', sans-serif;}
  .news:checked:after{top: 0px !important;}
}
@media (max-width: 767px){
  ::-webkit-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
  :-ms-input-placeholder {color: #818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
  ::placeholder {color:#818486; font-size: 12px; font-family: 'Montserrat', sans-serif;}
  .comercial strong, .comercial p, .comercial a {font-size: 14px;}
  .contato-banner{grid-template-columns: 1fr; text-align: center;}
  .comercial{text-align: center;}
  .form-contato-content{grid-template-columns: 1fr; text-align: center; gap: 0px;}
  .line{display: none;}
  #form-contato form .form-button{width: 100%; margin-bottom: 20px;}
  .form-width {width: 100%;}
  .modal-contato{width: 90%;}
  .modal-icon-x{right: 10px;}
}


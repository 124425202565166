*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html, body {
  height: 100%;
  margin: 0;
}

body{
  color: #fff;
  background-color: #ffffff;
}

.container{
  max-width: 1600px;
  margin: 0 auto;
}

#root{
  min-height: calc(100vh - 70px);
}

ul{
  list-style: none;
}

li {
  cursor: pointer;
}

a{
  text-decoration: none;
}

button{
  outline: none;
  cursor: pointer;
}

section {
  padding: 15px 0px;
}

.banner-menu{font-size: 15px;  position: relative; top: 44px;}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  .container{
    max-width: 1400px;
    margin: 0 auto;
  }  
}

@media (min-width: 1200px) and (max-width: 1399px){
  .container{
    max-width: 1200px;
    margin: 0 auto;
  }  
}

@media (max-width: 1199px){
  .container {
    max-width: 100%;
    margin: 0 10px;
  }
}
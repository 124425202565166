.accordion-list{list-style: none; margin: 0; padding: 0; font-family: 'Montserrat', sans-serif;}
.accordion-list li{padding: 12px 9px; margin: 14px 0px 0px 0px; border: 1px solid #707070; display: flex; align-items: center;}
.accordion-list li b{color: #377138; font-size: 20px; line-height: 24px; font-weight: 400; text-transform: uppercase;}
.accordion-list li span{padding: 0px 9px 0px 0px;}
.accordion-list div{color: #818486; font-size: 18px; line-height: 30px; font-weight: 400; border: 1px solid #707070; border-top: 0; padding: 12px 20px;}
.icon-accordion{margin-left: auto;}
.open {display: block;}
.close {display: none;}
.pet{list-style: disc !important;}
.pet li{border: none !important; padding: 6px 9px;  margin: 0px 20px; display: list-item;}

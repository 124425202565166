.logo{width: 290px; height: auto; margin: 0 auto; padding: 40px 0px;}
hr{background-color:#e8e8e8; color:#e8e8e8; border: none; height: 1px;}
/*header{position: absolute; width: 100%; background: #ffffff; height: auto; top: 30px;}*/
header ul{font-family: 'Montserrat', sans-serif; font-size: 15px; display: flex;
flex-direction: row; justify-content: space-between; align-items: baseline; padding: 10px 0px;}
header ul li{position: relative;}
header ul li a, header ul li span{text-transform: uppercase; color: #485860;}
header ul li svg{position: relative; top: 2px; left: 0px;}
header ul li a:hover, header ul li span:hover{color: #6ba968; font-weight: bold; border-bottom: 2px solid #6ba968; padding-bottom: 10px;}
.active{color: #6ba968; font-weight: bold; border-bottom: 2px solid #6ba968; padding-bottom: 10px;}
.mobile button, .onlymobile{display: none;}
.logo-mobile, .hide{display: none;}
/*Submenus content*/
.submenu, .submenu-desk{display: block; }
.submenu li a {text-transform: none; line-height: 30px;}
.submenu-desk li a{text-transform: none; line-height: 20px;}
.submenu li a:hover, .submenu-desk li a:hover{border: none; margin-bottom: 0;}
.submenu-desk{width: 160px; position: absolute; top: 31px; padding: 0px; background: #fff; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
z-index: 2;}
.submenu-desk li{padding: 5px;}
/*Cinema*/
.desk{position: absolute; width: 100%; background-color: #ffffff; z-index: 2; left: 0px; right: 0px;}
.cinema-menu-content{display: grid; grid-template-columns: 0.1fr 0.01fr 1fr; gap: 50px; 
  font-family: 'Montserrat', sans-serif; padding-bottom: 30px}
.cinema-menu-content h1{color: #485860; font-size: 16px; line-height: 19px; text-transform: uppercase; padding: 16px 0px;}
.box-filmes{padding: 10px 0px; display: flex;flex-direction: row;gap: 30px;}
.filme{background: #000; width: 246px; height: 369px;}
/*Acontece no parque e Clube do Parque*/
.acontece-no-parque-menu-content, .clube-do-parque-menu-content{display: grid; grid-template-columns: 0.5fr 0.01fr 0.3fr 0.01fr 0.01fr; gap: 50px; 
  font-family: 'Montserrat', sans-serif; padding-bottom: 30px}
.acontece-no-parque-menu-content h1, .clube-do-parque-menu-content h1{color: #485860; font-size: 16px; line-height: 19px; text-transform: uppercase; padding: 16px 0px;}
.acontece-no-parque-menu-content span, .clube-do-parque-menu-content span{font-weight: 400; color: #485860; font-size: 18px; line-height: 22px;}
.acontece-no-parque-menu-content .link, .clube-do-parque-menu-content .link{font-size: 18px; line-height: 22px; font-weight: 700; color: #485860; display: block;
  padding-top: 10px;}
.destaque{background: #000; width: 543px; height: 305px; margin-bottom: 10px;}
.destaque img{object-fit: cover; height: 100%;}
/*Area Logada*/
.area-logada{padding-top: 10px !important;}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
  header ul{font-size: 13px;}
  .logo{padding: 20px 0px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
  header ul{font-size: 13px;}
  .logo{padding: 20px 0px; width: 230px;}
  .destaque{width: 413px; height: auto;}
  .destaque img{width: 100%;}
}

@media (max-width: 1199px){
  .mobile{display: grid; grid-template-columns: 40px 1fr; margin: 0 10px; align-items: center; justify-items: center;}
  .mobile button{display: block;}
  .logo{margin: 0;}
  .logo-desk{display: none;}
  .logo-mobile{display: block;}
  .header-mobile-active{height: auto;}
  .header-mobile-inative ul{display: none;}
  header ul{flex-direction: column;}
  header ul li, header ul li span{padding: 0px 0px 20px 0px;}
  header ul li a:hover, header ul li span:hover{border: 0; padding: 0;}
  .desk{display: none !important;}
  .onlymobile{display: block; padding-top: 10px;}
  .submenu-desk{display: none;}
  .submenu li, .submenu{padding: 0;}
  .onlymobile a {padding-right: 20px;}
}

@media (max-width: 767px){
  header{position: sticky !important; top: 0px; z-index: 2; background: #ffffff;}
  .logo {width: 180px; padding: 10px 0px;}
  .logo img {width: 90%;}
  .onlymobile{display: block;}
  .onlymobile a{padding-right: 20px;}
  .desk{display: none !important;}
}

@media (max-width: 540px){
  #g-recaptcha {
    transform: initial;
  }
}

@media (max-width: 360px){
  #g-recaptcha {
    transform: scale(0.75);
    margin-bottom: 10px;
  }

  #g-recaptcha div{
    width: 224px;
  }
}

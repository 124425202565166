#acontece-no-parque-page{ background-image: url('../../assets/Acontece-no-Parque/eventos_banner.jpg'); background-size: cover;
  background-position: center top; background-repeat: no-repeat;}
#acontece-no-parque-novidades-page{ background-image: url('../../assets/Acontece-no-Parque/novidades_banner.jpg'); background-size: cover;
    background-position: center top; background-repeat: no-repeat;}
#acontece-no-parque-horarios-page{ background-image: url('../../assets/Acontece-no-Parque/nossos_horarios_banner.jpg'); background-size: cover;
      background-position: center top; background-repeat: no-repeat;}
#acontece-no-parque-biblioteca-page{ background-image: url('../../assets/Acontece-no-Parque/biblioteca_banner.jpg'); background-size: cover;
        background-position: center top; background-repeat: no-repeat;}
.acontece-no-parque-banner, .acontece-no-parque-novidades-banner{height: 70vh; color: #fff; font-family: 'Montserrat', sans-serif;}
.acontece-no-parque-filter, .acontece-no-parque-novidades-filter{height: 100%; background: rgba(0,0,0,0.6);}
.banner{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
    padding-bottom: 80px;}
.banner span{font-size: 18px; line-height: 22px; text-transform: uppercase;}
.banner h1{font-size: 60px; line-height: 73px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.banner hr{background-color:#ffffff; color:#ffffff; border: none; height: 1px; margin-bottom: 20px;}
.banner p{max-width: 786px;font-size: 22px; line-height: 27px;}
/*Champions*/
#champions{padding-top: 0px; margin-top: 10px;}
.champions{display: flex; flex-direction: column; justify-content: flex-end; height: 100%; max-width: 1600px; margin: 0 auto;
  padding-bottom: 50px;}
.champions-content{font-family: 'Montserrat', sans-serif; height: 640px;}
.champions-content .container{width: 1600px;}
.champions-content h1 {font-size: 60px; line-height: 60px; font-weight: 700; text-transform: uppercase; margin: 15px 0px;}
.champions-content p {max-width: 1090px; font-size: 19px; line-height: 25px;}
.champions-content button {margin: 20px 0px; background-color: transparent; border: 1px solid #ffffff; color: #ffffff;
  text-transform: uppercase; font-size: 22px; line-height: 27px; width: 354px; height: 60px; border-radius: 0px; transition: background-color 0.2s;}
.champions-content button:hover{background-color: #ffffff; color: #485860; border: 1px solid #ffffff; font-weight: 400;}
#informativo p, #informativo strong{color: #485860;font-size: 18px;line-height: 25px;max-width: 100%;padding-bottom: 20px; font-family: 'Montserrat', sans-serif;}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.banner, .champions{max-width: 1400px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p{font-size: 16px;}
.champions-content h1{font-size: 30px;}
.champions-content p{font-size: 16px;}
#informativo p, #informativo strong{font-size: 16px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
.banner, .champions{max-width: 1200px;}
.banner h1{font-size: 50px; line-height: 60px;}
.banner p{font-size: 16px;}
.champions-content h1{font-size: 30px;}
.champions-content p{font-size: 16px;}

}

@media(max-width: 1199px){
.banner, .champions{max-width: 100%; margin: 0 10px;}
.banner h1, .champions-content h1{font-size: 2em; line-height: 36px;}
.banner p{font-size: 16px;}
.champions-content p{font-size: 16px;}
}

@media (max-width: 767px){
.banner {padding-bottom: 50px;}
.champions-content  button{width: 100%;height: 45px;font-size: 14px;}
#informativo p, #informativo strong{font-size: 14px;}
}